import React from 'react'
import NumbersBgImage from '../images/bg/bg-stats.jpg'
import { StaticImage } from "gatsby-plugin-image"

const HomeClient = () => {

    return (
        <section style={{ paddingTop: '0px' }} id="portfolio" data-scrollax-parent="true" >
        <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }"></div>
        <div className="container">
            <div className="section-title fl-wrap">
              {/*   <h3>CLIENT</h3>*/}
                <h2>TECHNOLOGY STACK <span>GLOBAL USERS</span></h2>
            </div>
            <div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <p>Fortune 1000 companies including.</p>
                            <div className="fl-wrap">
                            <div className="container">
                                <ul className="client-list client-list-white">
                                    <li><a target="_blank" href="https://amadeus.com/" ><StaticImage src="../images/portflio/amadeus.png" className='respimg' alt="amadeus" title="Amadeus" /></a></li>
                                    <li><a target="_blank" href="https://www.docker.com/" ><StaticImage src="../images/portflio/docker.png" className='respimg' alt="docker" title="Docker" /></a></li>
                                    <li><a target="_blank" href="https://ericsson.com" ><StaticImage src="../images/portflio/ericsson.png" className='respimg' alt="ericsson" title="Ericsson" /></a></li>
                                    <li><a target="_blank" href="https://microsoft.com/" ><StaticImage src="../images/portflio/microsoft.png" className='respimg' alt="microsoft" title="Microsoft" /></a></li>
                                    <li><a target="_blank" href="https://salesforce.com//" ><StaticImage src="../images/portflio/salesforce.png" className='respimg' alt="salesforce" title="Salesforce" /></a></li>
                                    <li><a target="_blank" href="https://cisco.com/" ><StaticImage src="../images/portflio/cisco.png" className='respimg' alt="cisco" title="Cisco" /></a></li>
                                    <li><a target="_blank" href="https://siemens.com/" ><StaticImage src="../images/portflio/siemens.png" className='respimg' alt="siemens" title="Siemens" /></a></li>
                                    <li><a target="_blank" href="https://dell.com/" ><StaticImage src="../images/portflio/dell.png" className='respimg' alt="dell" title="Dell" /></a></li>
                                    <li><a target="_blank" href="https://tripadvisor.com"><StaticImage src="../images/portflio/tripadvisor.png" className='respimg' alt="tripadvisor" title="Tripadvisor" /></a></li>
                                    <li><a target="_blank" href="https://jpmorgan.com/"><StaticImage src="../images/portflio/jpmorgan.png" className='respimg' alt="JPMorgan" title=" JPMorgan" /></a></li>
                                    <li><a target="_blank" href="https://jpmorganchase.com/" ><StaticImage src="../images/portflio/jpmorganco.png" className='respimg' alt="Chase&Co" title="Chase&Co" /></a></li>
                                    <li><a target="_blank" href="https://redis.io/"><StaticImage src="../images/portflio/redis.png" className='respimg' alt="redis" title="Redis" /></a></li>
                                    <li><a target="_blank" href="https://ebay.com/" ><StaticImage src="../images/portflio/ebay.png" className='respimg' alt="ebay" title="eBay" /></a></li>
                                    <li><a target="_blank" href="https://bloomberg.com/" ><StaticImage src="../images/portflio/bloomberg.png" className='respimg' alt="bloomberg" title="Bloomberg" /></a></li>
                                    <li><a target="_blank" href="https://verizon.com/" ><StaticImage src="../images/portflio/verizon.png" className='respimg' alt="verizon" title="Verizon" /></a></li>
                                    <li><a target="_blank" href="https://dropbox.com/" ><StaticImage src="../images/portflio/dropbox.png" className='respimg' alt="dropbox" title="Dropbox" /></a></li>
                                    <li><a target="_blank" href="https://db.com/" ><StaticImage src="../images/portflio/deutsche.png" className='respimg' alt="deutsche" title="Deutsche" /></a></li>
                                    <li><a target="_blank" href="https://www.telekom.com/" ><StaticImage src="../images/portflio/t.png" className='respimg' alt="telekom" title="Telekom" /></a></li>
                                    <li><a target="_blank" href="https://argusmedia.com" ><StaticImage src="../images/portflio/argus.png" className='respimg' alt="argus" title="Argus" /></a></li>
                                    <li><a target="_blank" href="https://eurotech.com/" ><StaticImage src="../images/portflio/eurotech.png" className='respimg' alt="eurotech" title="Eurotech" /></a></li>
                                   
                                </ul>
                            </div>
                        </div>
                           
                           
                            {/* <a href="portfolio.html" className="btn float-btn flat-btn color-btn">My Portfolio</a> */}
                        </div>
                    </div>
            {/* <div className="fl-wrap mar-top">
                <div className="srv-link-text">
                    <h4>Need more info ? Visit my services page :  </h4>
                    <a href="services.html" className="btn float-btn flat-btn color-btn">My Services</a>
                </div>
            </div> */}
        </div>
        <div className="bg-parallax-module" data-position-top="90"  data-position-left="30" data-scrollax="properties: { translateY: '-150px' }"></div>
        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
        <div className="sec-lines">
            <div className="container full-height"><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div></div>
        </div>
    </section>
    )
}

export default HomeClient
