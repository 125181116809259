import React from 'react'
import NumbersBgImage from '../images/bg/bg-stats.jpg'

const HomeStats = () => {

    return (
        <>
        <style>
    {`
    .section-title::before {
        content: '';
        display: none !important;
       
    }
         .section-title {
       margin-bottom:0px !important;
       
    }
      
    `}
</style>
        <section id="highlights" className="dark-bg"  data-scrollax-parent="true">
            <div className="bg home-sec-bg par-elem"  data-scrollax="properties: { translateY: '30%' }"></div>
    
            <div className="container">
                <div className="section-title">
                    <h2 style={{paddingBottom:'0px !important'}}>FEATURED   <span>HIGHLIGHTS </span></h2>

                    <div className="horizonral-subtitle"><span>HIGHLIGHTS</span></div>
                </div>
                <div class="features-box-container fl-wrap"><div class="row"><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-gear-icon"></i></div><h3>100% Customizable</h3><p>Tailored to your exact needs</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-web-icon"></i></div><h3>100% Web Browser Based</h3><p>No client end software is required</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fab pragicts-responsive-icon"></i></div><h3>100% Responsive</h3><p>Run it on any Internet-enabled device.</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-open-icon"></i></div><h3>100% Open Source</h3><p>Deploy it on any platform/configuration</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fab pragicts-live-icon"></i></div><h3>Live Dashboard</h3><p>For real-time tracking and corrective action</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-report-icon"></i></div><h3>100% Customizable Reports</h3><p>Reports to meet your requirements</p></div></div></div>
            </div>
        </section>
        </>
    )
}

export default HomeStats
